import { NETBOX_DCIM_ENDPOINT } from '../../../../constants';
import { Badge } from '../../../../shared/components/Badge';
import { Olt, OltState } from '../../../../shared/types/provisioning/Olt';
import { Icon } from '@iconify/react';

type Props = {
  olt: Olt;
};

export function Details({ olt }: Props) {
  const featureFlag = (name: string, active: boolean) => (
    <div className="flex items-center gap-1 text-sm mb-1">
      {active ? (
        <Icon className="text-green" icon="codicon:pass" width={20} />
      ) : (
        <Icon className="text-muted" icon="codicon:error" width={20} />
      )}
      <span>{name}</span>
    </div>
  );

  return (
    <div>
      <div className="card flex flex-col gap-2 sidepanel">
        <div>
          <div className="mb-1">
            <strong>State</strong>
          </div>
          <Badge
            type={
              olt.state === OltState.DEPROVISIONED
                ? undefined
                : olt.state === OltState.ERROR
                  ? 'red'
                  : olt.state === OltState.PROVISIONED
                    ? 'green'
                    : 'blue'
            }
          >
            {olt.state}
          </Badge>
        </div>
        <div>
          <div className="mb-1">
            <strong>Name</strong>
          </div>
          {olt.name}
        </div>
        <div>
          <div className="mb-1">
            <strong>Model</strong>
          </div>
          {olt.model}
        </div>
        <div>
          <div className="mb-1">
            <strong>Netbox</strong>
          </div>
          <a
            href={`${NETBOX_DCIM_ENDPOINT}/devices/${olt.netboxId}`}
            target="_blank"
            rel="noreferrer"
          >
            {olt.name}
          </a>{' '}
          <span className="text-muted">({olt.ip})</span>
        </div>
        <div>
          <div className="mb-1">
            <strong>Flags</strong>
          </div>
          {featureFlag('Legacy Provisioning', olt.legacy || false)}
          {featureFlag('Full Config Provisioning', olt.fullConfigProvisioning)}
        </div>
      </div>
    </div>
  );
}
